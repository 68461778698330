import { useEffect, useState, useMemo, useRef, FC } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  OverlayView,
  Polyline,
} from "@react-google-maps/api";
import axios from "axios";
import MapPin from "../../assets/icons/MapPin.svg";
import MapPinStart from "../../assets/icons/MapPinStart.svg";
import MapPinDriver from "../../assets/icons/MapPinDriver.svg";

import { useDarkMode } from "../../context/DarkModeContext";
import {
  CustomStyledSelect,
  LGBDatepicker,
  PageLayout,
  SplitTable,
  StatusBadge,
} from "../../components";
import { ICustomer, IRouteItem, ITableRow } from "../../types";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "../../types/order/IOrder";
import InfoCard from "../../components/molecules/Cards/InfoCard";
import { HiOutlineTruck, HiOutlineUser, HiShoppingBag } from "react-icons/hi";
import { useDrivers, useOrders, useVehicles } from "../../hooks";
import { useWorkspace } from "../../context/WorkspaceContext";
import { Order } from "../../models";
import Driver from "./../../models/Driver";
import MapStopCard from "../../components/molecules/Cards/MapStopCard";
const customStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
];

export interface IMapRoute {
  customerName: string;
  customer: ICustomer;
  id: string;
  type: string;
  status: OrderStatus;
  driver?: Driver;
  route: IRouteItem[];
}

const containerStyle = {
  width: "100%",
  height: "680px",
  // borderRadius: "10px",
};

const initialCenter = {
  lat: 62.75769482961424,
  lng: 16.89871699772572,
};

export const DriverMapPage: FC = function () {
  const { t } = useTranslation(["common", "orders", "validation", "stats"]);
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAM96XcDAVzjfmaK37WzmAU8iMYM8Ka4j8",
    libraries: ["marker", "places", "geometry"],
  });
  const { isDarkMode } = useDarkMode();
  const [filteredOrders, setfilteredOrders] = useState<IMapRoute[]>([]);
  const { activeWorkspace } = useWorkspace();
  const [selectedRoute, setSelectedRoute] = useState<IMapRoute>();
  const [selectedStop, setSelectedStop] = useState<IRouteItem>();
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [paths, setPaths] = useState<{
    [key: string]: google.maps.LatLngLiteral[];
  }>({}); //Map of previously fetched paths to reduce amounts of Google Maps queries.
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedDrivers, setSelectedDrivers] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();

  /***********
   * QUERIES *
   ***********/
  const query = useOrders(activeWorkspace?.workspaceId ?? "", [
    OrderStatus.Scheduled,
    OrderStatus.New,
    OrderStatus.InProgress,
  ]);
  const driverQuery = useDrivers(activeWorkspace?.workspaceId ?? "");
  const vehiclesQuery = useVehicles(activeWorkspace?.workspaceId ?? "");
  const orders = useMemo(() => query.data ?? [], [query]) as Order[];
  const drivers = useMemo(() => driverQuery.data ?? [], [driverQuery]);
  const vehicles = useMemo(() => vehiclesQuery.data ?? [], [vehiclesQuery]);

  const mapRef = useRef<google.maps.Map | null>(null);

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  /**************
   * TABLE ROWS *
   **************/
  useEffect(() => {
    if (filteredOrders) {
      setTableRows(
        filteredOrders.map((order) => {
          return {
            id: order.id,
            isActive: false,
            onRowClickData: order,
            cells: [
              {
                id: "orderId",
                children: order.id,
              },
              {
                id: "type",
                children: "Pickup",
              },
              {
                id: "status",
                children: <StatusBadge status={order.status} />,
                showOnSmallScreen: true,
              },
            ],
          };
        }),
      );
    }
  }, [filteredOrders]);

  /**
   * Maps the orders to a suitable object to work with in the map.
   */
  useEffect(() => {
    let mappedOrders = orders.map((x) => {
      return {
        customerName: x.customer.isCompany
          ? x.customer.name
          : x.customer.firstName + " " + x.customer.lastName,
        customer: x.customer,
        id: x.id,
        type: "Pickup",
        status: x.status,
        driver: drivers.find((d) => d.email === x.driverId),
        route: x.route,
      } as IMapRoute;
    });
    setfilteredOrders(mappedOrders || []);
  }, [orders, drivers]);

  /**
   * TODO: Update table based on filter and search
   */
  // Update filteredOrders based on the searchQuery
  // useEffect(() => {
  //   let filteredData = [...orders];
  //   if (searchQuery) {
  //     filteredData = filteredData.filter((order) =>
  //       order.customer.firstName
  //         ?.toLowerCase()
  //         .includes(searchQuery.toLowerCase()),
  //     );
  //   }
  //   if (selectedDate) {
  //     //Filter date when we have proper data
  //   }
  //   if (selectedTypes) {
  //     // location.type is formatted incorrectly in mock data so this filter doesn't work yet.
  //     // filteredData = filteredData.filter(
  //     //   (location) => selectedTypes.includes(location.type),
  //     // );
  //   }
  //   if (selectedDrivers) {
  //     // Rewrite when we have proper data
  //     // filteredData = filteredData.filter((location) =>
  //     //   selectedDrivers.includes(location.firstName),
  //     // );
  //   }
  //   setfilteredOrders(filteredData);
  // }, [searchQuery, orders, selectedDate, selectedTypes, selectedDrivers]);

  /**
   * This function calls Google Map's Routes API which returns a encoded polyline string.
   * We decode this string, and add it to the 'paths' state to be used in the map. This is the path
   * that displays the lines between all the stops.
   *
   * @param coordinates An array of lat/long coordinates
   * @param id orderId
   * @returns void
   */
  const fetchRoutePath = async (
    coordinates: google.maps.LatLngLiteral[],
    id: string,
  ) => {
    if (coordinates.length < 2) return;

    try {
      const response = await axios.post(
        `https://routes.googleapis.com/directions/v2:computeRoutes`,
        {
          origin: {
            location: {
              latLng: {
                latitude: coordinates[0].lat,
                longitude: coordinates[0].lng,
              },
            },
          },
          destination: {
            location: {
              latLng: {
                latitude: coordinates[coordinates.length - 1].lat,
                longitude: coordinates[coordinates.length - 1].lng,
              },
            },
          },
          intermediates: coordinates.slice(1, -1).map((stop) => ({
            location: {
              latLng: {
                latitude: stop.lat,
                longitude: stop.lng,
              },
            },
          })),
          // routingPreference: "TRAFFIC_AWARE", // Do we want this? These are billed at a higher rate
          travelMode: "DRIVE",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key": "AIzaSyAM96XcDAVzjfmaK37WzmAU8iMYM8Ka4j8",
            "X-Goog-FieldMask": "routes.polyline", // For now we'll only fetch polylines to render the path. Add more fields if needed later. (https://developers.google.com/maps/documentation/routes/reference/rest/v2/TopLevel/computeRoutes)
          },
        },
      );

      if (
        response.data &&
        response.data.routes &&
        response.data.routes.length > 0
      ) {
        const route = response.data.routes[0];
        // Decode the polyline to get the path
        const path = google.maps.geometry.encoding
          .decodePath(route.polyline.encodedPolyline)
          .map((stop) => ({
            lat: stop.lat(),
            lng: stop.lng(),
          }));

        // Store the new path in the 'previousPaths' map so we can reuse it later without having to fetch it.
        setPaths((prev) => ({
          ...prev,
          [id]: path,
        }));
      }
    } catch (error) {
      console.error("Error fetching route from Routes API:", error);
    }
  };

  /**
   * This function is called when the user clicks an order/route in the left hand table. Based on the
   * coordinates of the selected route, we set the centerpoint and bounds/zoom level to fit the entire route in view.
   *
   * If the path for the selected route haven't been fetched already, we call fetchRoutePath to get the polylines to display
   * it on the map. If it has already been fetched, we don't do this. We simply update the selected route which will let the component
   * fetch the corresponding path: paths[selectedRoute.id]
   *
   * @param mapRoute Selected route.
   * @returns void
   */
  const setRoute = (mapRoute: IMapRoute) => {
    if (selectedRoute && selectedRoute.id === mapRoute.id) return; // Prevent redundant calls

    setSelectedRoute(mapRoute);
    if (mapRef.current) {
      // Extract coordinates from the route
      const coordinates = mapRoute.route.map((x) => ({
        lat: x.location.coordinate?.lat ?? 0,
        lng: x.location.coordinate?.lon ?? 0,
      }));

      // Center map to the selected route and set bounds.
      setBoundsAndCenterpoint(coordinates);

      // Check if the path for this order already exists in previousPaths to limit API queries
      if (!paths[mapRoute.id]) {
        fetchRoutePath(coordinates, mapRoute.id);
      }
    }
  };

  /**
   * This function calculates and sets the centerpoint of the selected route. It then calculates the route's
   * bounds and sets a suitable zoom level resulting in the entire route being visible in the maps view.
   *
   * @param coordinates An array of lat/lng coordinates.
   */
  const setBoundsAndCenterpoint = (
    coordinates: google.maps.LatLngLiteral[],
  ) => {
    if (mapRef.current) {
      // Calculate and set center point
      const centerPoints = {
        centerLat:
          coordinates.reduce((sum, coord) => sum + coord.lat, 0) /
          coordinates.length,
        centerLng:
          coordinates.reduce((sum, coord) => sum + coord.lng, 0) /
          coordinates.length,
      };
      mapRef.current.setCenter({
        lat: centerPoints.centerLat,
        lng: centerPoints.centerLng,
      });

      // Calculate the route bounds and set the map to fit these bounds
      const bounds = new window.google.maps.LatLngBounds();
      coordinates.forEach((coord) => bounds.extend(coord));
      mapRef.current.fitBounds(bounds);
    }
  };

  const selectStop = (stop?: IRouteItem) => {
    setSelectedStop(stop);
    if (mapRef.current) {
      if (stop) {
        mapRef.current.setCenter({
          lat: stop.location.coordinate?.lat ?? 0,
          lng: stop.location.coordinate?.lon ?? 0,
        });
        mapRef.current.setZoom(14);
      } else {
        if (selectedRoute) {
          setBoundsAndCenterpoint(
            selectedRoute?.route.map((x) => ({
              lat: x.location.coordinate?.lat ?? 0,
              lng: x.location.coordinate?.lon ?? 0,
            })),
          );
        }
      }
    }
  };

  /*************
   * PIN ICONS *
   *************/
  const waypointPin = useMemo<google.maps.Icon | undefined>(() => {
    if (!isLoaded) return undefined;
    return {
      url: MapPin,
      scaledSize: new window.google.maps.Size(28, 28),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(15, 15),
      labelOrigin: new window.google.maps.Point(15, 15),
    };
  }, [isLoaded]);

  const startPin = useMemo<google.maps.Icon | undefined>(() => {
    if (!isLoaded) return undefined;
    return {
      url: MapPinStart,
      scaledSize: new window.google.maps.Size(24, 24),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(15, 15),
      labelOrigin: new window.google.maps.Point(15, 15),
    };
  }, [isLoaded]);

  const driverPin = useMemo<google.maps.Icon | undefined>(() => {
    if (!isLoaded) return undefined;
    return {
      url: MapPinDriver,
      scaledSize: new window.google.maps.Size(24, 24),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(15, 15),
      labelOrigin: new window.google.maps.Point(15, 15),
    };
  }, [isLoaded]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <PageLayout>
      <SplitTable
        tableWidth="w-1/3"
        contentWidth="w-2/3"
        searchable
        forceOpen
        tableRows={tableRows}
        onRowClick={(order) => setRoute(order)}
        updateTable={(val) => setSearchQuery(val)}
        contentOverTable={
          <div className="grid grid-cols-2 gap-2 justify-between pb-4">
            <div className="col-span-1">
              <CustomStyledSelect
                id="driver"
                placeholder={t("orders:create.driver_selection.title")}
                isMultiple
                contentBelow
                options={orders.map((x) => {
                  return {
                    key: x.id,
                    label:
                      x.customer.firstName + " " + x.customer.lastName ||
                      x.customer.name ||
                      "",
                  };
                })}
                onSelectChanged={(e) => {
                  setSelectedDrivers(e);
                }}
              />
            </div>
            <div className="col-span-1">
              <CustomStyledSelect
                id="types"
                isMultiple
                placeholder={t("orders:choose_status")}
                contentBelow
                options={[
                  {
                    key: OrderStatus.InProgress,
                    label: t("stats:orderStatus.in_progress"),
                  },
                  {
                    key: OrderStatus.Scheduled,
                    label: t("stats:orderStatus.scheduled"),
                  },
                  {
                    key: OrderStatus.Completed,
                    label: t("stats:orderStatus.completed"),
                  },
                ]}
                onSelectChanged={(e) => {
                  setSelectedTypes(e);
                }}
              />
            </div>
            <div className="col-span-2">
              <LGBDatepicker
                select={setSelectedDate}
                selectedDate={selectedDate}
              />
            </div>
          </div>
        }
        content={
          <div className="w-full">
            <div className="flex justify-between gap-4 mb-4">
              <div className="w-full">
                <InfoCard
                  icon={<HiOutlineUser className="h-7 w-7 text-lgb-grey-600" />}
                  title="Driver"
                  description={
                    selectedRoute
                      ? selectedRoute?.driver?.firstName +
                        " " +
                        selectedRoute?.driver?.lastName
                      : "--"
                  }
                  titleClass="text-lgb-grey-600"
                  descriptionClass="text-lgb-grey-600"
                  containerClass="bg-lgb-grey-100"
                />
              </div>
              <div className="w-full">
                <InfoCard
                  icon={
                    <HiOutlineTruck className="h-7 w-7 text-lgb-grey-600" />
                  }
                  title="Vehicle"
                  description={
                    vehicles.find(
                      (x) => x.id === selectedRoute?.driver?.vehicleId || "",
                    )?.registrationNumber || "--"
                  } //TODO: Find vehicle
                  titleClass="text-lgb-grey-600"
                  descriptionClass="text-lgb-grey-600"
                  containerClass="bg-lgb-grey-100"
                />
              </div>
              <div className="w-full">
                <InfoCard
                  icon={<HiShoppingBag className="h-7 w-7 text-lgb-grey-600" />}
                  title="Customer"
                  description={selectedRoute?.customerName || "--"}
                  titleClass="text-lgb-grey-600"
                  descriptionClass="text-lgb-grey-600"
                  containerClass="bg-lgb-grey-100"
                />
              </div>
            </div>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={initialCenter}
              zoom={4}
              onLoad={onLoad}
              options={{
                streetViewControl: true,
                mapTypeControl: true,
                styles: isDarkMode ? customStyles : null,
                gestureHandling: "greedy",
              }}
            >
              {selectedRoute &&
                selectedRoute.route.map((stop, index) => (
                  <Marker
                    key={index}
                    position={{
                      lat: stop.location.coordinate?.lat ?? 0,
                      lng: stop.location.coordinate?.lon ?? 0,
                    }}
                    icon={index === 0 ? startPin : waypointPin}
                    onClick={() => selectStop(stop)}
                  />
                ))}

              {/* Display the route path */}
              {selectedRoute && paths[selectedRoute.id] ? (
                <>
                  {console.log(
                    "Rendering path for id:" +
                      selectedRoute.id +
                      " and path list: ",
                    paths[selectedRoute.id],
                  )}
                  <Polyline
                    key={`polyline-${selectedRoute.id}`}
                    path={paths[selectedRoute.id]}
                    options={{
                      strokeColor: "#7214FF",
                      strokeOpacity: 0.8,
                      strokeWeight: 4,
                    }}
                  />
                </>
              ) : null}
              {selectedRoute && selectedStop && (
                <>
                  <OverlayView
                    position={{
                      lat: selectedStop?.location.coordinate?.lat ?? 0,
                      lng: selectedStop?.location.coordinate?.lon ?? 0,
                    }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <MapStopCard
                      routeInfo={selectedRoute}
                      stopInfo={selectedStop}
                      close={() => selectStop(undefined)}
                    />
                  </OverlayView>
                </>
              )}
            </GoogleMap>
          </div>
        }
        tableHeaders={[
          {
            id: "orderId",
            children: t("common:orderId"),
          },
          {
            id: "type",
            children: t("common:date"),
          },
          {
            id: "status",
            children: t("common:status"),
          },
        ]}
      />
    </PageLayout>
  );
};
